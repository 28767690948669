export class Drink {

  constructor(
    public id: number,
    public id_stammtisch: number,
    public name: string,
    public description: string,
    public price: number,
    public size: number,
  ) {
  }
}
