import {Injectable} from '@angular/core';
import {RouterStateSnapshot, TitleStrategy} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppTitleService extends TitleStrategy {
  postfixTitle: string = 'Stammtisch App 3';
  defaultTitle: string = 'Stammtisch App 3';
  separatorTitle: string = ' - ';

  currentTitleValue: BehaviorSubject<string> = new BehaviorSubject<string>(this.defaultTitle);
  currentAppTitleValue: BehaviorSubject<string> = new BehaviorSubject<string>(this.defaultTitle);
  timer: number | undefined = undefined;
  timerMS: number = 5 * 1000;

  constructor() {
    super();
    this.currentTitleValue.subscribe((newTitle: string) => {
      this.currentAppTitleValue.next(newTitle);
    });
  }

  get title$(): Observable<string> {
    return this.currentTitleValue.asObservable();
  }

  get appTitle$(): Observable<string> {
    return this.currentAppTitleValue.asObservable();
  }

  override updateTitle(routerState: RouterStateSnapshot): void {
    const currentRoutingTitle: string = this.buildTitle(routerState) ?? '';
    let newDocumentTitle: string = '';
    let newAppTitle: string = '';
    if (currentRoutingTitle === '') {
      newDocumentTitle = this.postfixTitle;
      newAppTitle = this.postfixTitle;
    } else {
      newDocumentTitle = currentRoutingTitle + this.separatorTitle + this.postfixTitle;
      newAppTitle = currentRoutingTitle;
    }

    this.setDocumentTitle(newDocumentTitle);
    this.setAppTitle(newAppTitle);
  }

  private setDocumentTitle(newTitle: string): void {
    document.title = newTitle;
  }

  private setAppTitle(newTitle: string): void {
    this.currentTitleValue.next(newTitle);
    this.timerCurrentAppTitle();
  }

  private timerCleanup(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  private timerCurrentAppTitle(): void {
    this.currentAppTitleValue.next(this.currentTitleValue.getValue());
    this.timerCleanup();
    this.timer = setTimeout(() => {
        this.timerDefaultAppTitle();
      },
      this.timerMS,
    );
  }

  private timerDefaultAppTitle(): void {
    this.currentAppTitleValue.next(this.defaultTitle);
    this.timerCleanup();
    this.timer = setTimeout(() => {
        this.timerCurrentAppTitle();
      },
      this.timerMS,
    );
  }

}
