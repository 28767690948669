import {CurrencyPipe} from '@angular/common';
import {Component, Inject} from '@angular/core';
import {MatButton, MatFabButton, MatIconButton} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle} from '@angular/material/dialog';
import {MatIcon} from '@angular/material/icon';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Drink} from '../../../../model/drink.model';
import {StammtischsService} from '../../../../service/stammtischs.service';

@Component({
  selector: 'app-drink-booking-dialog',
  standalone: true,
  imports: [
    CurrencyPipe,
    MatButton,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    MatFabButton,
    MatIcon,
    MatIconButton,
  ],
  templateUrl: './drink-booking-dialog.component.html',
})
export class DrinkBookingDialogComponent {
  loading: boolean = false;
  stammtischId: number = 27;

  constructor(
    public dialogRef: MatDialogRef<DrinkBookingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public drink: Drink,
    private stammtischsService: StammtischsService,
    private snackbar: MatSnackBar,
  ) {
    this.stammtischId = this.stammtischsService.selectedStammtischID;
  }

  onNoClick(): void {
    this.loading = true;
    // console.warn('buchen');
    this.stammtischsService
      .createDrunkByStammtisch(this.stammtischId, this.drink.id)
      .then(() => {
        // console.warn('buchen ok');
        this.loading = false;
        this.snackbar.open('Buchung erfolgreich');
        this.dialogRef.close(true);
      })
      .catch(() => {
        // console.warn('buchen error');
        this.loading = false;
        this.snackbar.open('Buchungsfehler');
        this.dialogRef.close(false);
      });
  }
}
