@if (dataSource.data.length <= 0) {
  <div>
    <app-card-warning text="Da ist etwas schief gegangen, du solltest Mitglied deines Stammtischs sein."></app-card-warning>
  </div>
} @else {
  <div>
    <table mat-table [dataSource]="dataSource" matSort>
      <!-- Column -->
      <ng-container matColumnDef="id_user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell>1</td>
        <td mat-cell *matCellDef="let element"> {{element.id_user}}</td>
      </ng-container>
      <!-- Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let element"> {{element.name}}</td>
      </ng-container>
      <!-- Column -->
      <ng-container matColumnDef="sumDebts">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Schulden</th>
        <td mat-cell *matCellDef="let element">
          {{element.sumDebts * 0.01 | currency}}
        </td>
      </ng-container>
      <!-- Column -->
      <ng-container matColumnDef="sumPayments">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Bezahlt</th>
        <td mat-cell *matCellDef="let element">
          {{element.sumPayments * 0.01 | currency}}
        </td>
      </ng-container>
      <!-- Column -->
      <ng-container matColumnDef="balance">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Guthaben</th>
        <td mat-cell *matCellDef="let element">
          {{element.balance * 0.01 | currency}}
        </td>
      </ng-container>
      <!-- Header -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    @if (pageSizeOptions[0] < dataSource.data.length) {
      <mat-paginator
        [pageSizeOptions]="pageSizeOptions"
        showFirstLastButtons
      />
    }
  </div>
  <app-members-balance-charts/>
}
<ng-template #menu_more>
  <button mat-menu-item>
    <mat-icon>edit</mat-icon>
    Meinen Namen ändern (ToDo)
  </button>
  <mat-divider/>
  <button mat-menu-item>
    <mat-icon>person_add</mat-icon>
    Mitglied hinzufügen (ToDo)
  </button>
  <button mat-menu-item>
    <mat-icon>person_remove</mat-icon>
    Mitglied entfernen (ToDo)
  </button>
  <button mat-menu-item *appDevelop>
    <mat-icon>taunt</mat-icon>
    Rolle ändern (ToDo)
  </button>
  <mat-divider/>
  <button mat-menu-item (click)="doNotifyMembersOpening()">
    <mat-icon>play_circle</mat-icon>
    Benachrichtigung Stammtischeröffnung
  </button>
  <button mat-menu-item (click)="doNotifyMembersClosing()">
    <mat-icon>stop_circle</mat-icon>
    Benachrichtigung Stammtischende
  </button>
</ng-template>
