import {Component, OnInit} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs';
import {AppNavigationSideComponent} from './app-navigation-side/app-navigation-side.component';
import {AppShellService} from './service/app-shell.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  standalone: true,
  imports: [AppNavigationSideComponent],
})
export class AppComponent implements OnInit {
  title: string = 'stammtischApp3';

  constructor(
    private router: Router,
    private appShellService: AppShellService,
  ) {
  }

  ngOnInit() {
    // this.router.events.subscribe(value => console.log(value));
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
    ).subscribe(() => {
      // console.log('NavigationEnd: ', event);
      this.appShellService.reset(); // reset if navigation
      // Your logic here. This block will run every time the route changes.
    });
  }
}
