export interface Help {
  title: string,
  icon: string,
  description: string,
}

export const helpData: Help[] = [
  {
    'title': 'Buchen',
    'icon': 'local_bar',
    'description': 'Hier kannst du alle Getränke die dein ausgewählter Stammtisch zur Verfügung stellt buchen. Oben rechts, kannst du deinen aktuellen Kontostand einsehen. Die Buchung eines Getränkes muss nochmals bestätigt werden. Der Preis des gebuchten Getränkes wird mit deinem aktuellen Kontostand verrechnet.',
  },
  {
    'title': 'Mitgliederliste',
    'icon': 'list',
    'description': 'Hier siehst du alle Mitglieder des ausgewählten Stammtisches. Dabei wird dir der Nutzername und die ID des Nutzers angezeigt.',
  },
  {
    'title': 'Bezahlen',
    'icon': 'attach_money',
    'description': 'Hier kannst du deinen Kontostand einsehen und Geld einzahlen. Du kannst Guthaben aufbauen oder auch deine Schulden bezahlen. Bitte denke daran, dass du das eingezahlte Geld auch wirklich in der Kasse deines Stammtisches hinterlegst.',
  },
  {
    'title': 'Stammtischverwaltung',
    'icon': 'build',
    'description': 'Hier kannst du alle den Stammtisch betreffenden Verwaltungsaufgaben erledigen. Als normaler Stammtischnutzer kannst du hier nur deinen Nutzernamen für diesen Stammtisch ändern. Als Stammtischowner kannst du auch neue Getränke hinzufügen, bestehende Getränke verändern und neue Mitglieder hinzufügen.',
  },
  {
    'title': 'Deine Stammtische',
    'icon': 'home',
    'description': 'Hier erhältst du eine Übersicht der Stammtische in denen du Mitglied bist. Deinen aktuell ausgewählten Stammtisch erkennst du an dem gelben Punkt. Den ausgewählten Stammtisch wählst du durch einen Klick auf den Stammtischnamen aus.',
  },
  {
    'title': 'Accountverwaltung',
    'icon': 'account_box',
    'description': 'Hier kannst du alle account spezifischen Einstellungen verwalten. Besonders wichtig ist die ID. Mit dieser ID kannst du von Stammtischownern zu den Stammtischen hinzugefügt werden.',
  },
  {
    'title': 'Logout',
    'icon': 'exit_to_app',
    'description': 'Hier kannst du dich aus der Stammtischwebapp ausloggen.',
  },
  {
    'title': 'Hilfe',
    'icon': 'help',
    'description': 'Hier kommst du zu der Hilfe die du gerade liest.',
  },
  {
    'title': 'Feedback',
    'icon': 'feedback',
    'description': 'Hier wirst du auf eine externe Seite umgeleitet. Auf dieser Seite kannst du Fehler melden, dem Entwicklerteam Feeback geben oder dir neue Features wünschen. Wir freuen uns sehr über jede Rückmeldung.',
  },
];
