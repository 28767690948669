import {APP_INITIALIZER, DEFAULT_CURRENCY_CODE, importProvidersFrom, LOCALE_ID} from '@angular/core';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import {bootstrapApplication, BrowserModule} from '@angular/platform-browser';
import {provideAnimations} from '@angular/platform-browser/animations';
import {TitleStrategy} from '@angular/router';
import {ServiceWorkerModule} from '@angular/service-worker';
import {provideCharts, withDefaultRegisterables} from 'ng2-charts';
import {AppRoutingModule} from './app/app-routing.module';


import {AppComponent} from './app/app.component';
import {AppInitService, initializeApp1} from './app/service/app-init.service';
import {AppTitleService} from './app/service/app-title.service';

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(AppRoutingModule, BrowserModule,
      // ChartModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: true,
        // enabled: environment.production,
        // Register the ServiceWorker as soon as the app is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000',
      }),
    ),
    AppInitService,
    {provide: APP_INITIALIZER, useFactory: initializeApp1, deps: [AppInitService], multi: true},
    {provide: LOCALE_ID, useValue: 'de-DE'},
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 5 * 1000}},
    //{provide: TitleStrategy, useClass: AppTitleService},
    {provide: TitleStrategy, useExisting: AppTitleService},
    provideCharts(withDefaultRegisterables()),
    provideAnimations(),
  ],
})
  .catch(err => console.error(err));
