@if (!appShellService.isMobile && backButtonTarget === null) {
  <button mat-icon-button class="burger-icon" aria-label="Menu"
          (click)="appShellService.sideNavToggle()">
    <mat-icon>menu</mat-icon>
  </button>
}
@if (backButtonTarget) {
  <button mat-icon-button class="burger-icon" aria-label="Menu"
          [routerLink]="backButtonTarget">
    <mat-icon>arrow_back</mat-icon>
  </button>
}
<span class="app-name">
    {{appTitleService.appTitle$|async}}
  </span>
<span class="spacer"></span>
<!--  <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">-->
<!--    <mat-icon>favorite</mat-icon>-->
<!--  </button>-->
<!--  <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">-->
<!--    <mat-icon>share</mat-icon>-->
<!--  </button>-->
@if (moreMenu) {
  <button mat-icon-button class="burger-icon" [matMenuTriggerFor]="belowMenu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #belowMenu="matMenu" yPosition="below">
    <ng-container [ngTemplateOutlet]="moreMenu"></ng-container>
  </mat-menu>
}
