import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HelpComponent} from './help/help.component';
import {LegalComponent} from './legal/legal.component';
import {ErrorComponent} from './noauth/error/error/error.component';
import {ForbiddenComponent} from './noauth/error/forbidden/forbidden.component';
import {NotFoundComponent} from './noauth/error/not-found/not-found.component';
import {LoginComponent} from './noauth/login/login.component';
import {ResetPasswortComponent} from './noauth/reset-passwort/reset-passwort.component';
import {SetPasswordComponent} from './noauth/set-password/set-password.component';
import {stammtischResolver} from './routing/resolver/stammtisch.resolver';
import {RoutingPath} from './routing/routing-path.const';
import {AchievementsComponent} from './stammtischs/stammtisch/achievements/achievements.component';
import {DrinksComponent} from './stammtischs/stammtisch/drinks/drinks.component';
import {MembersComponent} from './stammtischs/stammtisch/members/members.component';
import {StammtischHistoryComponent} from './stammtischs/stammtisch/stammtisch-history/stammtisch-history.component';
import {StammtischWrapperComponent} from './stammtischs/stammtisch/stammtisch-wrapper/stammtisch-wrapper.component';
import {StammtischComponent} from './stammtischs/stammtisch/stammtisch.component';
import {StammtischsComponent} from './stammtischs/stammtischs.component';


export const appRoutes: Routes = [
  // {path: '', component: MainComponent},
  {path: '', pathMatch: 'full', redirectTo: RoutingPath.stammtisch},

  {path: RoutingPath.login, component: LoginComponent},
  {path: RoutingPath.resetPasswort, component: ResetPasswortComponent},
  {path: RoutingPath.passwort, component: SetPasswordComponent},
  {
    path: 'settings',
    // component: SettingsComponent,
    loadChildren: () => import('./settings/settings-routing.modules').then(m => m.SettingsRoutingModules),
  },
  {path: 'legal', component: LegalComponent, title: 'Rechtliches'},
  {path: 'help', component: HelpComponent, title: 'Hilfe'},

  {
    path: RoutingPath.stammtisch,
    children: [
      {path: '', component: StammtischsComponent, title: 'Deine Stammtische'},
      {
        path: RoutingPath.Stammtisch.id,
        resolve: {stammtischID: stammtischResolver},
        children: [
          {path: '', pathMatch: 'full', redirectTo: RoutingPath.Stammtisch.drinks},
          {path: 'overview', component: StammtischComponent, title: ''},
          {path: 'wrapper', component: StammtischWrapperComponent},
          {path: RoutingPath.Stammtisch.achievements, component: AchievementsComponent},
          {path: RoutingPath.Stammtisch.drinks, component: DrinksComponent, title: 'Getränkeauswahl'},
          {path: RoutingPath.Stammtisch.members, component: MembersComponent, title: 'Mitglieder'},
          {path: RoutingPath.Stammtisch.history, component: StammtischHistoryComponent, title: 'Historie'},
          {
            path: RoutingPath.Stammtisch.drunks,
            // component: DrunksComponent,
            loadChildren: () => import('./stammtischs/stammtisch/drunks/drunks-routing.module').then(m => m.DrunksRoutingModule),
          },
          {
            path: RoutingPath.Stammtisch.payments,
            // component: PaymentsComponent,
            loadChildren: () => import('./stammtischs/stammtisch/payments/payments-routing.module').then(m => m.PaymentsRoutingModule),
          },
        ],
      },
    ],
  },

  {path: RoutingPath.error, component: ErrorComponent, title: 'Fehler'},
  {path: RoutingPath.forbidden, component: ForbiddenComponent, title: 'Verboten'},
  {path: RoutingPath.notFound, component: NotFoundComponent, title: 'Nicht gefunden'},
  {path: '**', redirectTo: RoutingPath.notFound},
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        useHash: true,
        scrollPositionRestoration: 'top',
      },
    ),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule {
}
