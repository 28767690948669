import {Component} from '@angular/core';
import {MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {ThemeSwitchService} from '../../service/theme-switch.service';

@Component({
  selector: 'app-button-dark-mode-toggle',
  templateUrl: './button-dark-mode-toggle.component.html',
  standalone: true,
  imports: [
    MatIcon,
    MatIconButton,
  ],
})
export class ButtonDarkModeToggleComponent {
  constructor(
    public themeSwitch: ThemeSwitchService,
  ) {
  }
}
