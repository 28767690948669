@if (drinks.length <= 0) {
  <div>
    <app-card-warning text="Es sind noch keine Getränke vorhanden!"></app-card-warning>
  </div>
} @else {
  <div>
    @for (drink of drinks; track drink) {
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>{{drink.name}}</mat-card-title>
          <mat-card-subtitle>{{drink.description}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="container">
          <div class="details">
            <mat-icon>euro_symbol</mat-icon>
            &nbsp;{{drink.price * 0.01 | currency}}
            <mat-icon>local_drink</mat-icon>
            &nbsp;{{drink.size / 1000 | number}}&#x202F;Liter
          </div>
          <div class="book">
            @if (false) {
              <button mat-raised-button disabled>
                <mat-icon>info</mat-icon>
                Info
              </button>
            }
            @if (modeEditing) {
              <button mat-raised-button (click)="openDeleteDrink(drink)">
                <mat-icon>delete_forever</mat-icon>
                Löschen
              </button>
              <button mat-raised-button (click)="openChangeDrink(drink)">
                <mat-icon>build</mat-icon>
                Ändern
              </button>
            } @else {
              <button mat-raised-button color="accent" (click)="openBookingDialog(drink)">
                <mat-icon>shopping_cart_checkout</mat-icon>
                Buchen
              </button>
            }
          </div>
        </mat-card-content>
      </mat-card>
      <br>
    }
  </div>
}

<ng-template #menu_more>
  <button mat-menu-item (click)="openCreateDrink()">
    <mat-icon>add</mat-icon>
    Getränk erstellen
  </button>
  <button mat-menu-item (click)="modeEditing=!modeEditing">
    <mat-icon>edit</mat-icon>
    Getränke ändern
  </button>
  <button mat-menu-item [routerLink]="RoutingPath.Stammtisch.Drunks.rootPath(stammtischId)">
    <mat-icon>history</mat-icon>
    Getränkehistorie
  </button>
</ng-template>
