<h2>Licenses</h2>
<p>
  Used to create the app.
  <mat-icon>favorite</mat-icon>
</p>
<cdk-virtual-scroll-viewport class="scroll-viewport" itemSize="1">
<pre>
  <code>
    {{licenses|async}}
  </code>
</pre>
</cdk-virtual-scroll-viewport>
