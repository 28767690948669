<mat-sidenav-container class="sidenav-container">
  <mat-sidenav class="sidenav" fixedInViewport
               [attr.role]="(appShellService.showSideNav$ | async) ? 'dialog' : 'navigation'"
               [mode]="appShellService.currentSideNavMode.getValue()"
               [opened]="appShellService.showSideNav$|async"
               (keydown.escape)="appShellService.sideNavClose()"
               (closedStart)="appShellService.sideNavClose()"
  >
    <app-side-menu></app-side-menu>
  </mat-sidenav>

  <mat-sidenav-content class="sidenav-content">
    <!--    <mat-toolbar class="sidenav-content-header-placeholder">-->
    <!--      <app-top-header></app-top-header>-->
    <!--    </mat-toolbar>-->
    <mat-toolbar class="sidenav-content-header">
      <mat-toolbar-row>
        <app-top-header/>
      </mat-toolbar-row>
    </mat-toolbar>
    <!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar>-->
    <!-- Content here. -->
    <div class="sidenav-content-body">
      <router-outlet/>
    </div>
    @if (appShellService.stammtisch) {
      <div class="quick-nav-placeholder">
        <app-quick-nav class=""></app-quick-nav>
      </div>
      <div class="quick-nav">
        <app-quick-nav class=""></app-quick-nav>
      </div>
    }
  </mat-sidenav-content>

</mat-sidenav-container>
