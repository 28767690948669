import {Component} from '@angular/core';
import {LegalAngularLicensesComponent} from './legal-angular-licenses/legal-angular-licenses.component';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  standalone: true,
  imports: [
    LegalAngularLicensesComponent,
  ],
})
export class LegalComponent {
}
