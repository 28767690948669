import {CurrencyPipe} from '@angular/common';
import {AfterViewInit, Component, TemplateRef, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatDivider} from '@angular/material/divider';
import {MatIcon} from '@angular/material/icon';
import {MatMenuItem} from '@angular/material/menu';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort, MatSortHeader} from '@angular/material/sort';
import {MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable, MatTableDataSource} from '@angular/material/table';
import {DevelopDirective} from '../../../directives/develop.directive';
import {CardWarningComponent} from '../../../elements/card-warning/card-warning.component';
import {DialogConfirmComponent, DialogConfirmData} from '../../../elements/dialog-confirm/dialog-confirm.component';
import {Member} from '../../../model/member.model';
import {RoutingPath} from '../../../routing/routing-path.const';
import {AppShellService} from '../../../service/app-shell.service';
import {StammtischsService} from '../../../service/stammtischs.service';
import {MembersBalanceChartsComponent} from './members-balance-charts/members-balance-charts.component';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrl: './members.component.css',
  standalone: true,
  imports: [
    CardWarningComponent,
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatSortHeader,
    MatCell,
    MatCellDef,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatPaginator,
    MembersBalanceChartsComponent,
    MatMenuItem,
    MatIcon,
    MatDivider,
    DevelopDirective,
    CurrencyPipe,
  ],
})
export class MembersComponent implements AfterViewInit {
  protected readonly RoutingPath = RoutingPath;

  dataSource = new MatTableDataSource<Member>([]);
  // displayedColumns: string[] = ['id_user', 'name', 'sumDebts', 'sumPayments', 'balance'];
  displayedColumns: string[] = ['id_user', 'name', 'balance'];
  pageSizeOptions: number[] = [5, 10, 50];

  @ViewChild('menu_more', {static: false}) menuMore: TemplateRef<void> | null = null;

  @ViewChild(MatPaginator, {static: false})
  set paginator(value: MatPaginator) { // Required because non-static data and rendering.
    if (this.dataSource) {
      this.dataSource.paginator = value;
    }
  }

  @ViewChild(MatSort, {static: false})
  set sort(value: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = value;
    }
  }

  constructor(
    private stammtischsService: StammtischsService,
    private appShellService: AppShellService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
  ) {
    this.stammtischsService.getMembers().subscribe(
      (members) => {
        this.dataSource.data = [];
        this.dataSource.data.push(...members);
      });
  }

  ngAfterViewInit(): void {
    this.appShellService.moreMenu.next(this.menuMore);
  }

  doNotifyMembersOpening(): void {
    const data: DialogConfirmData = {
      text: 'Benachrichtige alle Mitglieder über Stammtischeröffnung?',
      title: 'Stammtischeröffnung?',
    };
    this.dialog
      .open(
        DialogConfirmComponent,
        {data: data},
      )
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.stammtischsService.doNotifyMembersOpening()
            .then(() => this.snackbar.open('Stammtischeröffnung erfolgreich.'))
            .catch(() => this.snackbar.open('Stammtischeröffnung fehlgeschlagen.'))
          ;
        }
      });
  }

  doNotifyMembersClosing(): void {
    const data: DialogConfirmData = {
      text: 'Benachrichtige alle Mitglieder über Stammtischende?',
      title: 'Ende Gelände?',
    };
    this.dialog
      .open(
        DialogConfirmComponent,
        {data: data},
      )
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.stammtischsService.doNotifyMembersClosing()
            .then(() => this.snackbar.open('Stammtischende erfolgreich.'))
            .catch(() => this.snackbar.open('Stammtischende fehlgeschlagen.'))
          ;
        }
      });
  }
}
