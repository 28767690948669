<!--<h2>Stammtisch Historie</h2>-->
@if (events.length === 0) {
  <div>
    Noch nichts vorhanden
    <mat-icon>sentiment_dissatisfied</mat-icon>
    .
    <!-- <mat-icon>sentiment_sad</mat-icon>.-->
  </div>
} @else {
  <ng-template #eventCustomTemplate let-event=event>
    <div class="event-card-inner">
      <b>{{event?.eventInfo?.title}}</b><br>
      {{event?.eventInfo?.description}}
    </div>
  </ng-template>

  <ngx-timeline
    [events]="events"
    [reverseOrder]="true"
    [groupEvent]="NgxTimelineEventGroup.DAY_MONTH_YEAR"
    [innerEventCustomTemplate]="eventCustomTemplate"
    langCode="de"
  ></ngx-timeline>
}
