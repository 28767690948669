import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {MatCard, MatCardActions, MatCardContent, MatCardHeader, MatCardTitle} from '@angular/material/card';
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {RouterLink} from '@angular/router';
import {FormValidators} from '../../helper/form-validators';
import {RoutingPath} from '../../routing/routing-path.const';
import {LoginService} from '../login/login.service';

@Component({
  selector: 'app-reset-passwort',
  templateUrl: './reset-passwort.component.html',
  styleUrl: './reset-passwort.component.css',
  standalone: true,
  imports: [
    MatCard,
    MatCardTitle,
    MatLabel,
    MatCardHeader,
    MatCardContent,
    MatFormField,
    ReactiveFormsModule,
    RouterLink,
    MatCardActions,
    MatError,
    MatButton,
    MatInput,
  ],
})
export class ResetPasswortComponent implements OnInit {

  resetSuccess: boolean = false;
  resetGroup: FormGroup;
  RoutingPath = RoutingPath;

  constructor(
    private loginService: LoginService,
    private formBuilder: FormBuilder,
  ) {
    this.resetGroup = formBuilder.group({
      // eslint-disable-next-line @typescript-eslint/unbound-method
      email: ['', [Validators.required, Validators.email, Validators.pattern(new RegExp(FormValidators.REGEX_EMAIL)), Validators.maxLength(64)]],
    });
  }

  ngOnInit(): void {
  }

  resetPassword(): void {
    this.loginService.resetPassword(this.resetGroup.getRawValue())
      .then(() => {
        this.resetSuccess = true;
        console.log('this.resetSuccess', this.resetSuccess);
      })
      .catch(() => {
        this.resetSuccess = true;
        console.log('this.resetSuccess', this.resetSuccess);
      });
  }
}
