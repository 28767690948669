@if (!resetSuccess) {
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>Neues Passwort anfordern</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="resetGroup" class="example-form">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>E-Mail-Adresse</mat-label>
          <input type="email" autocomplete="email" matInput formControlName="email">
          @if (resetGroup.controls['email'].invalid) {
            <mat-error>
              Please enter a valid email address.
            </mat-error>
          }
        </mat-form-field>
        <button mat-raised-button color="accent" [disabled]="resetGroup.invalid" (click)="resetPassword()">Absenden</button>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <button mat-flat-button color="primary" [routerLink]="RoutingPath.Login.rootPath">Zurück</button>
    </mat-card-actions>
  </mat-card>
} @else {
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>Ergebnis</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      E-Mail wurde an {{resetGroup.get('email')?.value}} versendet.
    </mat-card-content>
    <mat-card-actions>
      <button mat-flat-button color="primary" [routerLink]="RoutingPath.Login.rootPath">Zurück</button>
    </mat-card-actions>
  </mat-card>
}
