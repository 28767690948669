@if (!success) {
  <div>
    Neues Passwort setzen<br>
    {{error}}<br>
    <form [formGroup]="passwordGroup" class="example-form">
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Token</mat-label>
        <input type="text" autocomplete="one-time-code" matInput formControlName="token">
      </mat-form-field>

      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Neues Passwort</mat-label>
        <input type="password" autocomplete="new-password" matInput formControlName="password">
      </mat-form-field>

      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Passwort wiederholen</mat-label>
        <input type="password" autocomplete="new-password" matInput formControlName="passwordRetry">
      </mat-form-field>

      <button type="submit" mat-raised-button color="accent"
              (click)="setPassword()"
              [disabled]="!passwordGroup.valid || passwordGroup.get('password')?.value !== passwordGroup.get('passwordRetry')?.value">
        Absenden
      </button>
    </form>
  </div>
} @else {
  <div>
    Passwort wurde erfolgreich geändert.
  </div>
}
