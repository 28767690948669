import {Component, OnInit} from '@angular/core';
import {MatAnchor, MatButton} from '@angular/material/button';
import {MatDivider} from '@angular/material/divider';
import {MatIcon} from '@angular/material/icon';
import {MatList, MatListItem} from '@angular/material/list';
import {MatToolbar} from '@angular/material/toolbar';
import {Router, RouterLink} from '@angular/router';
import {DevelopDirective} from '../../directives/develop.directive';
import {ButtonDarkModeToggleComponent} from '../../elements/button-dark-mode-toggle/button-dark-mode-toggle.component';
import {LoginService} from '../../noauth/login/login.service';
import {RoutingPath} from '../../routing/routing-path.const';
import {AppShellService} from '../../service/app-shell.service';
import {EnvironmentService} from '../../service/environment.service';
import {StammtischsService} from '../../service/stammtischs.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.css',
  standalone: true,
  imports: [
    MatToolbar,
    MatList,
    DevelopDirective,
    MatListItem,
    MatButton,
    MatIcon,
    MatDivider,
    RouterLink,
    MatAnchor,
    ButtonDarkModeToggleComponent,
  ],
})
export class SideMenuComponent implements OnInit {
  RoutingPath = RoutingPath;

  constructor(
    public appShellService: AppShellService,
    public loginService: LoginService,
    public environmentService: EnvironmentService,
    public stammtischsService: StammtischsService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    // this.appShellService.sideNavOpen();
  }

  logout(): void {
    this.loginService.logout();
    void this.router.navigateByUrl(RoutingPath.login);
  }
}
