import {Component, OnInit} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {MatListItem, MatListItemLine, MatListItemTitle, MatNavList} from '@angular/material/list';
import {RouterLink} from '@angular/router';
import {Stammtisch} from '../model/stammtisch.model';
import {RoutingPath} from '../routing/routing-path.const';
import {AppShellService} from '../service/app-shell.service';
import {StammtischsService} from '../service/stammtischs.service';

@Component({
  selector: 'app-stammtischs',
  templateUrl: './stammtischs.component.html',
  styleUrl: './stammtischs.component.css',
  standalone: true,
  imports: [
    MatNavList,
    MatListItem,
    RouterLink,
    MatListItemTitle,
    MatIcon,
    MatListItemLine,
  ],
})
export class StammtischsComponent implements OnInit {
  RoutingPath = RoutingPath;

  stammtischs: Stammtisch[] = [];

  constructor(
    stammtischService: StammtischsService,
    appShellService: AppShellService,
  ) {
    stammtischService.getStammtischs().subscribe(value => {
      this.stammtischs = value;
    });
    appShellService.setStammtisch();
  }

  ngOnInit(): void {
  }
}
