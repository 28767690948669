@if (chartDatasets && 0 < chartLabels.length) {
  <div>
    <div style="display: block;">
      <canvas baseChart
              height="400"
              [type]="'pie'"
              [datasets]="chartDatasets"
              [labels]="chartLabels"
              [options]="chartOptions"
      ></canvas>
    </div>
  </div>
}
