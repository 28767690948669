import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {AsyncPipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {MatSidenav, MatSidenavContainer, MatSidenavContent} from '@angular/material/sidenav';
import {MatToolbar, MatToolbarRow} from '@angular/material/toolbar';
import {RouterOutlet} from '@angular/router';
import {RoutingPath} from '../routing/routing-path.const';
import {AppShellService} from '../service/app-shell.service';
import {QuickNavComponent} from './quick-nav/quick-nav.component';
import {SideMenuComponent} from './side-menu/side-menu.component';
import {TopHeaderComponent} from './top-header/top-header.component';

@Component({
  selector: 'app-app-navigation-side',
  templateUrl: './app-navigation-side.component.html',
  styleUrl: './app-navigation-side.component.css',
  standalone: true,
  imports: [
    MatSidenavContainer,
    MatSidenav,
    SideMenuComponent,
    MatSidenavContent,
    MatToolbar,
    MatToolbarRow,
    TopHeaderComponent,
    RouterOutlet,
    QuickNavComponent,
    AsyncPipe,
  ],
})
export class AppNavigationSideComponent implements OnInit {
  RoutingPath = RoutingPath;

  constructor(
    public appShellService: AppShellService,
    public breakpointObserver: BreakpointObserver,
  ) {
  }

  ngOnInit(): void {
    this.breakpointObserver
      .observe(Breakpoints.Handset)
      .subscribe((state: BreakpointState) => {
        this.permaShowSideNav(!state.matches);
        // if (state.matches) {
        //   console.log('Viewport width is handset!');
        // } else {
        //   console.log('Viewport width is not handset!');
        // }
      });
  }

  public permaShowSideNav(value: boolean): void {
    if (value) {
      this.appShellService.setDesktop();
    } else {
      this.appShellService.setMobile();
    }
  }
}
