import {AsyncPipe, NgTemplateOutlet} from '@angular/common';
import {Component, OnInit, TemplateRef} from '@angular/core';
import {MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {MatMenu, MatMenuTrigger} from '@angular/material/menu';
import {RouterLink} from '@angular/router';
import {AppShellService} from '../../service/app-shell.service';
import {AppTitleService} from '../../service/app-title.service';

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrl: './top-header.component.css',
  standalone: true,
  imports: [
    MatIconButton,
    MatIcon,
    RouterLink,
    MatMenuTrigger,
    MatMenu,
    NgTemplateOutlet,
    AsyncPipe,
  ],
})
export class TopHeaderComponent implements OnInit {
  public backButtonTarget: string | null = null;
  public moreMenu: TemplateRef<void> | null = null;

  constructor(
    public appShellService: AppShellService,
    public appTitleService: AppTitleService,
  ) {
  }

  ngOnInit(): void {
    this.appShellService.moreMenu.subscribe((value) => {
      this.moreMenu = value;
    });
    this.appShellService.backButton.subscribe((value) => {
      this.backButtonTarget = value;
    });
  }
}
