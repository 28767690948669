@if (appShellService.isMobile) {
  <div>
    <mat-toolbar>
      Navigation
    </mat-toolbar>
  </div>
}

<mat-list class="inline-list">
  <ng-container *appDevelop>
    @if (!appShellService.isMobile) {
      <div>
        <mat-list-item class="inline-list">
          <button mat-button color="accent" (click)="appShellService.sideNavClose()">
            <mat-icon>close</mat-icon>
            Schließen
          </button>
        </mat-list-item>
        <mat-divider></mat-divider>
      </div>
    }
  </ng-container>
  <!--  <mat-list-item>-->
  <!--    <button mat-flat-button color="accent" routerLink="/">-->
  <!--      <mat-icon>home</mat-icon>-->
  <!--      Start-->
  <!--    </button>-->
  <!--  </mat-list-item>-->
  <mat-list-item>
    <button mat-flat-button color="accent" [routerLink]="RoutingPath.Stammtisch.rootPath">
      <mat-icon>explore</mat-icon>
      Stammtische
    </button>
  </mat-list-item>
  <mat-divider></mat-divider>
  @if ((appShellService.stammtisch?.id ?? 0) !== 0) {
    <mat-list-item>
      <button mat-button [routerLink]="RoutingPath.Stammtisch.Drinks.rootPath(stammtischsService.selectedStammtischID)">
        <mat-icon>local_bar</mat-icon>
        Buchen
      </button>
    </mat-list-item>
    <mat-list-item>
      <button mat-button [routerLink]="RoutingPath.Stammtisch.History.rootPath(stammtischsService.selectedStammtischID)">
        <mat-icon>history_edu</mat-icon>
        Historie
      </button>
    </mat-list-item>
    <mat-list-item>
      <button mat-button [routerLink]="RoutingPath.Stammtisch.Payments.rootPath(stammtischsService.selectedStammtischID)">
        <mat-icon>attach_money</mat-icon>
        Bezahlen
      </button>
    </mat-list-item>
    <mat-list-item>
      <button mat-button [routerLink]="RoutingPath.Stammtisch.Members.rootPath(stammtischsService.selectedStammtischID)">
        <mat-icon>groups</mat-icon>
        Mitglieder
      </button>
    </mat-list-item>
    <mat-divider></mat-divider>
  }
  <!--  <mat-list-item>-->
  <!--    @if (loginService.currentUserValue) {-->
  <!--      <span>-->
  <!--        <mat-icon style="font-size: 1.125rem;">email</mat-icon>-->
  <!--&lt;!&ndash;        {{loginService.currentUserValue.user.email}}&ndash;&gt;-->
  <!--      </span>-->
  <!--    }-->
  <!--  </mat-list-item>-->
  <mat-list-item>
    <button mat-button routerLink="settings">
      <mat-icon>settings</mat-icon>
      Einstellungen
    </button>
  </mat-list-item>
  @if (!environmentService.production) {
    <mat-list-item>
      <button mat-button routerLink="settings/debug">
        <mat-icon>engineering</mat-icon>
        Debug
      </button>
    </mat-list-item>
  }
  <mat-list-item class="inline-list">
    <button mat-button color="primary" (click)="appShellService.sideNavClose();logout()">
      <mat-icon>logout</mat-icon>
      Logout
    </button>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item>
    <button mat-button routerLink="help">
      <mat-icon>help</mat-icon>
      Hilfe
    </button>
  </mat-list-item>
  <mat-list-item>
    <button mat-button routerLink="legal">
      <mat-icon>gavel</mat-icon>
      Rechtliches
    </button>
  </mat-list-item>
  <mat-list-item>
    <a mat-button href="https://bitbucket.org/pointiswunderland/preview/issues/new" target="_blank" rel="noopener noreferrer">
      <mat-icon>feedback</mat-icon>
      Feedback
    </a>
  </mat-list-item>
  <mat-list-item style="text-align: center;">
    <app-button-dark-mode-toggle></app-button-dark-mode-toggle>
  </mat-list-item>
</mat-list>
