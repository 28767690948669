<h2>Achievements</h2>

@for (achievement of achievements; track achievement) {
  <mat-card class="achievement-card">
    <mat-card-header>
      <!--      <div mat-card-avatar class="example-header-image"></div>-->
      <mat-card-title>{{achievement.name}}</mat-card-title>
      <mat-card-subtitle>{{achievement.description}}</mat-card-subtitle>
    </mat-card-header>
    <img mat-card-image width="300" height="300" ngSrc="assets/achievements/img_{{achievement.id.toString().padStart(2, '0')}}.webp" alt="Foto von {{achievement.name}}">
  </mat-card>
}
