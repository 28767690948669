<h2 mat-dialog-title>Buchen</h2>
<mat-dialog-content class="mat-typography">
  Möchtest du ein {{drink.name}} für {{(drink.price / 100)|currency}}?
</mat-dialog-content>
<mat-dialog-actions align="end">
  @if (!loading) {
    <button mat-raised-button mat-dialog-close>Nein Danke!</button>
    <button mat-raised-button (click)="onNoClick()">Ja Logo!</button>
  } @else {
    <!--
    <button mat-fab extended disabled>
      <mat-icon>cloud_upload</mat-icon>
      Buche...
    </button>
    -->
    <button mat-raised-button disabled>Buche...</button>
  }
</mat-dialog-actions>
