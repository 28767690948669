import {Directive, TemplateRef, ViewContainerRef} from '@angular/core';
import {EnvironmentService} from '../service/environment.service';

@Directive({
  selector: '[appDevelop]',
  standalone: true,
})
export class DevelopDirective {
  constructor(
    private environmentService: EnvironmentService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<Element>,
  ) {
    // Do not view if production.
    if (this.environmentService.production) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
