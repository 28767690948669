import {CurrencyPipe, DatePipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {RouterLink} from '@angular/router';
import {DevelopDirective} from '../../directives/develop.directive';
import {Stammtisch} from '../../model/stammtisch.model';
import {RoutingPath} from '../../routing/routing-path.const';
import {StammtischsService} from '../../service/stammtischs.service';

@Component({
  selector: 'app-stammtisch',
  templateUrl: './stammtisch.component.html',
  styleUrl: './stammtisch.component.css',
  standalone: true,
  imports: [
    DevelopDirective,
    MatButton,
    RouterLink,
    MatIcon,
    CurrencyPipe,
    DatePipe,
  ],
})
export class StammtischComponent implements OnInit {
  RoutingPath = RoutingPath;

  stammtisch?: Stammtisch;
  saldo: number = 0;

  constructor(
    private stammtischsService: StammtischsService,
  ) {
  }

  ngOnInit(): void {
    this.stammtischsService.getStammtisch().subscribe((stammtisch) => {
      this.stammtisch = stammtisch;
    });
    this.stammtischsService.getMember().subscribe((member) => {
      if (member === undefined) {
        return;
      }
      this.saldo = member.balance;
    });
  }

}
