<!--<nav mat-tab-nav-bar #menu_more>-->
<mat-tab-group
  headerPosition="below"
  backgroundColor="primary"
  disablePagination="true"
  [selectedIndex]="selected.value"
  (selectedIndexChange)="selected.setValue($event)"
>
  <mat-tab>
    <ng-template mat-tab-label>
      <div [routerLink]="RoutingPath.Stammtisch.Drinks.rootPath(appShellService.stammtisch?.id ?? 0)">
        <mat-icon>local_bar</mat-icon>
        Buchen
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div [routerLink]="RoutingPath.Stammtisch.History.rootPath(appShellService.stammtisch?.id ?? 0)">
        <mat-icon>history_edu</mat-icon>
        Historie
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div [routerLink]="RoutingPath.Stammtisch.Payments.rootPath(appShellService.stammtisch?.id ?? 0)">
        <mat-icon>attach_money</mat-icon>
        Bezahlen
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
<!--</nav>-->
<!--<nav mat-tab-nav-bar-->
<!--     [tabPanel]="tabPanel"-->
<!--     animationDuration="0ms"-->
<!--     headerPosition="below"-->
<!--     mat-align-tabs="center"-->
<!--     backgroundColor="primary"-->
<!--     color="primary"-->
<!--&gt;-->
<!--  &lt;!&ndash;  <a mat-tab-link *ngFor="let link of links" (click)="activeLink = link" [active]="activeLink == link"> {{link}} </a>&ndash;&gt;-->
<!--  <a mat-tab-link [routerLink]="RoutingPath.Stammtisch.Drinks.rootPath(appShellService.stammtisch?.id ??0)">-->
<!--    <mat-icon>local_bar</mat-icon>-->
<!--    Buchen-->
<!--  </a>-->
<!--  <a mat-tab-link [routerLink]="RoutingPath.Stammtisch.History.rootPath(appShellService.stammtisch?.id??0)">-->
<!--    <mat-icon>history_edu</mat-icon>-->
<!--    Historie-->
<!--  </a>-->
<!--  <a mat-tab-link [routerLink]="RoutingPath.Stammtisch.Payments.rootPath(appShellService.stammtisch?.id ??0)">-->
<!--    <mat-icon>attach_money</mat-icon>-->
<!--    Bezahlen-->
<!--  </a>-->
<!--</nav>-->
<!--<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>-->
