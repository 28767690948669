@if (modeChange) {
  <h2 mat-dialog-title>Getränk ändern</h2>
} @else {
  <h2 mat-dialog-title>Erstelle einen neues Getränk</h2>
}
<mat-dialog-content class="mat-typography">
  <form>
    <mat-form-field class="full-width">
      <mat-label>Name (kann nach dem Erstellen nicht mehr geändert werden)</mat-label>
      <input matInput placeholder="" type="text" min="4" max="20" required name="name" [(ngModel)]="drink.name" [disabled]="modeChange">
    </mat-form-field>
    <br>
    <mat-form-field class="full-width">
      <mat-label>Beschreibung</mat-label>
      <input matInput placeholder="" type="text" max="60" required name="description" [(ngModel)]="drink.description">
    </mat-form-field>
    <br>
    <mat-form-field class="full-width">
      <mat-label>Preis (in Cent)</mat-label>
      <input matInput placeholder="" type="number" min="0" required name="price" [(ngModel)]="drink.price">
    </mat-form-field>
    <br>
    <mat-form-field class="full-width">
      <mat-label>Größe (in Milliliter)</mat-label>
      <input matInput placeholder="" type="number" min="0" required name="size" [(ngModel)]="drink.size">
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!--  @if (!loading) {-->
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
  <button mat-raised-button (click)="save()">Speichern</button>
  <!--  } @else {-->
  <!--    &lt;!&ndash;-->
  <!--    <button mat-fab extended disabled>-->
  <!--      <mat-icon>cloud_upload</mat-icon>-->
  <!--      Buche...-->
  <!--    </button>-->
  <!--    &ndash;&gt;-->
  <!--    <button mat-raised-button disabled>Buche...</button>-->
  <!--  }-->
</mat-dialog-actions>
