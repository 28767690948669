@if (stammtisch) {
  <div>
    <p>
      {{stammtisch.description}}
    </p>
    <ng-container *appDevelop>
      <p>
        ID: {{stammtisch.id}}<br>
        Name: {{stammtisch.name}}<br>
        Beschreibung: {{stammtisch.description}}<br>
        Erstellungsdatum: {{stammtisch.regtime * 1000 | date: 'short'}}<br>
      </p>
      @if (stammtisch.settings?.money_control) {
        <p>
          Guthabenbeschränkung (Schuldenbremse):<br>
          Aktiv:
          @if (stammtisch.settings?.money_control?.active) {
            Ja
          } @else {
            Nein
          }
          <br>
          Min: {{(stammtisch.settings?.money_control?.min || 0) * 0.01 | currency}}<br>
          Max: {{(stammtisch.settings?.money_control?.max || 0) * 0.01 | currency}}<br>
        </p>
      }
    </ng-container>
    <p>
      @if (0 <= saldo) {
        <span>Aktuelles Guthaben:</span>
      } @else {
        <span>Aktuelle Schulden:</span>
      }
      {{saldo * 0.01 | currency}}
    </p>
    <ng-container *appDevelop>
      <p>Options:</p>
      <p>
        <button mat-button [routerLink]="RoutingPath.Stammtisch.Drinks.rootPath(stammtisch.id)">
          <mat-icon>local_bar</mat-icon>
          Drinks
        </button>
      </p>
      <p>
        <button mat-button [routerLink]="RoutingPath.Stammtisch.Drunks.rootPath(stammtisch.id)">
          <mat-icon>history_edu</mat-icon>
          Drunks
        </button>
      </p>
      <p>
        <button mat-button [routerLink]="RoutingPath.Stammtisch.Payments.rootPath(stammtisch.id)">
          <mat-icon>attach_money</mat-icon>
          Payments
        </button>
      </p>
      <p>
        <button mat-button [routerLink]="RoutingPath.Stammtisch.Members.rootPath(stammtisch.id)">
          <mat-icon>list</mat-icon>
          Members
        </button>
      </p>
    </ng-container>
  </div>
}
