<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>Login zum Stammtisch</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="loginGroup" class="example-form">
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>E-Mail-Adresse</mat-label>
        <input type="email" autocomplete="email" matInput formControlName="email">
        @if (loginGroup.controls['email'].invalid) {
          <mat-error>
            Please enter a valid email address.
          </mat-error>
        }
      </mat-form-field>
      <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Passwort</mat-label>
        <input matInput [type]="hidePassword ? 'password' : 'text'" autocomplete="current-password" formControlName="password">
        <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hidePassword">
          <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        @if (loginGroup.controls['password'].invalid) {
          <mat-error>
            Please enter a password.
          </mat-error>
        }
      </mat-form-field>
      <button type="submit" mat-raised-button color="primary" (click)="login()" [disabled]="!loginGroup.valid">Anmelden</button>
      @if (errorText) {
        <div>{{errorText}}</div>
      }
    </form>
  </mat-card-content>
</mat-card>
<span class="mat-sub"></span>
<mat-divider></mat-divider>

<mat-card appearance="outlined">
  <mat-card-header>
    <mat-card-title>Keinen Login?</mat-card-title>
  </mat-card-header>
  <mat-card-actions>
    <button mat-flat-button color="accent" [routerLink]="RoutingPath.ResetPassword.rootPath">Passwort vergessen?</button>
    &nbsp;
    <button mat-flat-button color="accent" [routerLink]="RoutingPath.Register.rootPath">Jetzt Registrieren</button>
  </mat-card-actions>
</mat-card>
