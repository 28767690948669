import {CurrencyPipe, DecimalPipe} from '@angular/common';
import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatCard, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle} from '@angular/material/card';
import {MatDialog} from '@angular/material/dialog';
import {MatIcon} from '@angular/material/icon';
import {MatMenuItem} from '@angular/material/menu';
import {RouterLink} from '@angular/router';
import {CardWarningComponent} from '../../../elements/card-warning/card-warning.component';
import {DialogConfirmComponent, DialogConfirmData} from '../../../elements/dialog-confirm/dialog-confirm.component';
import {Drink} from '../../../model/drink.model';
import {RoutingPath} from '../../../routing/routing-path.const';
import {AppShellService} from '../../../service/app-shell.service';
import {StammtischsService} from '../../../service/stammtischs.service';
import {DrinkBookingDialogComponent} from './drink-booking-dialog/drink-booking-dialog.component';
import {DrinkCreateDialogComponent} from './drink-create-dialog/drink-create-dialog.component';

@Component({
  selector: 'app-drinks',
  templateUrl: './drinks.component.html',
  styleUrl: './drinks.component.css',
  standalone: true,
  imports: [
    CardWarningComponent,
    MatCard,
    MatCardHeader,
    MatCardTitle,
    MatCardSubtitle,
    MatCardContent,
    MatIcon,
    MatButton,
    MatMenuItem,
    RouterLink,
    DecimalPipe,
    CurrencyPipe,
  ],
})
export class DrinksComponent implements OnInit, AfterViewInit, OnDestroy {
  stammtischId: number;
  drinks: Drink[] = [];
  modeEditing: boolean = false;
  protected readonly RoutingPath = RoutingPath;

  @ViewChild('menu_more', {static: false}) menuMore: TemplateRef<void> | null = null;

  constructor(
    public dialog: MatDialog,
    private stammtischsService: StammtischsService,
    private appShellService: AppShellService,
  ) {
    this.stammtischId = stammtischsService.selectedStammtischID;
  }

  ngOnInit(): void {
    this.stammtischsService.getDrinks().subscribe(drinks => {
      this.drinks = drinks;
    });
  }

  ngAfterViewInit(): void {
    this.appShellService.moreMenu.next(this.menuMore);
  }

  ngOnDestroy(): void {
    this.appShellService.moreMenu.next(null);
  }

  openCreateDrink(): void {
    this.dialog.open(
      DrinkCreateDialogComponent,
    );
  }

  openChangeDrink(drink: Drink): void {
    this.dialog.open(
      DrinkCreateDialogComponent,
      {data: drink},
    );
  }

  openDeleteDrink(drink: Drink): void {
    const data: DialogConfirmData = {
      text: 'Lösche dauerhaft Drink "' + drink.name + '"?',
      title: 'Sicher?',
    };
    this.dialog
      .open(
        DialogConfirmComponent,
        {data: data},
      )
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          void this.stammtischsService.deleteDrink(drink); // fixme promise
        }
      });
  }

  openBookingDialog(drink: Drink): void {
    this.dialog.open(
      DrinkBookingDialogComponent,
      {data: drink},
    );
  }
}
