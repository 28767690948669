<div class="help-toggle-buttons">
  <button mat-raised-button (click)="accordion.openAll()">Alle ausklappen</button>
  &nbsp;
  <button mat-raised-button (click)="accordion.closeAll()">Alle einklappen</button>
</div>
<mat-accordion class="help-headers-align" multi>
  @for (item of data; track item) {
    <mat-expansion-panel togglePosition="before">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>{{item.icon}}</mat-icon>
          &nbsp; {{item.title}}
        </mat-panel-title>
        <!--      <mat-panel-description>-->
        <!--        Type your name and age-->
        <!--        <mat-icon>account_circle</mat-icon>-->
        <!--      </mat-panel-description>-->
      </mat-expansion-panel-header>
      {{item.description}}
    </mat-expansion-panel>
  }
</mat-accordion>
