import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButton, MatIconButton} from '@angular/material/button';
import {MatCard, MatCardActions, MatCardContent, MatCardHeader, MatCardTitle} from '@angular/material/card';
import {MatDivider} from '@angular/material/divider';
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field';
import {MatIcon} from '@angular/material/icon';
import {MatInput} from '@angular/material/input';
import {Router, RouterLink} from '@angular/router';
import {FormValidators} from '../../helper/form-validators';
import {User} from '../../model/user.model';
import {RoutingPath} from '../../routing/routing-path.const';
import {LoginService} from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
  standalone: true,
  imports: [
    MatButton,
    MatCard,
    MatCardActions,
    MatCardContent,
    MatCardHeader,
    MatCardTitle,
    MatDivider,
    MatError,
    MatFormField,
    MatIcon,
    MatIconButton,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    RouterLink,
  ],
})
export class LoginComponent implements OnInit {
  RoutingPath = RoutingPath;
  loginGroup: FormGroup;
  user: User | undefined;
  errorText: string = '';
  hidePassword: boolean = true;

  constructor(
    private router: Router,
    formBuilder: FormBuilder,
    private loginService: LoginService,
  ) {
    this.loginGroup = formBuilder.group({
      // eslint-disable-next-line @typescript-eslint/unbound-method
      email: ['', [Validators.required, Validators.email, Validators.pattern(new RegExp(FormValidators.REGEX_EMAIL)), Validators.maxLength(64)]],
      // eslint-disable-next-line @typescript-eslint/unbound-method
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(64)]],
    });
  }

  ngOnInit(): void {
  }

  login(): void {
    this.loginService.login(this.loginGroup.getRawValue()).then((loginData) => {
      this.user = loginData.user;
      void this.router.navigateByUrl(RoutingPath.stammtisch);
    }).catch(() => {
      this.errorText = 'Der Benutzername und/oder das Passwort sind nicht gültig.';
    });
  }
}
