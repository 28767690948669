import {Component, OnInit, ViewChild} from '@angular/core';
import {MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle} from '@angular/material/expansion';
import {MatIcon} from '@angular/material/icon';
import {Help, helpData} from './help.data';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrl: './help.component.css',
  standalone: true,
  imports: [
    MatIcon,
    MatExpansionPanelTitle,
    MatExpansionPanelHeader,
    MatExpansionPanel,
    MatAccordion,
  ],
})
export class HelpComponent implements OnInit {
  @ViewChild(MatAccordion) accordion!: MatAccordion;

  data: Help[] = helpData;

  constructor() {
  }

  ngOnInit(): void {
  }
}
