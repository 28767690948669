import {CurrencyPipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIcon} from '@angular/material/icon';
import {StammtischsService} from '../../../../service/stammtischs.service';
import {BalanceChartDebtComponent} from './balance-chart-debt/balance-chart-debt.component';
import {BalanceChartDepositComponent} from './balance-chart-deposit/balance-chart-deposit.component';

@Component({
  selector: 'app-members-balance-charts',
  standalone: true,
  imports: [
    BalanceChartDebtComponent,
    BalanceChartDepositComponent,
    CurrencyPipe,
    MatExpansionModule,
    MatIcon,
  ],
  templateUrl: './members-balance-charts.component.html',
  styleUrl: './members-balance-charts.component.scss',
})
export class MembersBalanceChartsComponent implements OnInit {
  balance: number = 0;
  debit: number = 0;
  deposit: number = 0;
  debit_disabled: boolean = true;
  deposit_disabled: boolean = true;

  constructor(
    private stammtischsService: StammtischsService,
  ) {
  }

  ngOnInit(): void {
    this.stammtischsService.getMembers().subscribe(members => {
      members.forEach(member => {
        if (0 < member.balance) {
          this.deposit += member.balance;
          this.deposit_disabled = false;
        } else {
          this.debit += (member.balance * -1);
          this.debit_disabled = false;
        }
      });
      this.balance = this.deposit - this.debit;
    });
  }
}
