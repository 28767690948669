<p>
  Du bist in {{stammtischs.length}} Stammtischen angemeldet.
</p>

<mat-nav-list>
  @for (stammtisch of stammtischs; track stammtisch) {
    <mat-list-item [routerLink]="stammtisch.id.toString()">
      <span matListItemTitle>
        <mat-icon>open_in_new</mat-icon>
        {{stammtisch.name}} #{{stammtisch.id}}
      </span>
      <span matListItemLine>
        {{stammtisch.description}}
      </span>
    </mat-list-item>
  }
</mat-nav-list>
