import {CurrencyPipe} from '@angular/common';
import {Component, Inject} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle} from '@angular/material/dialog';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Drink} from '../../../../model/drink.model';
import {StammtischsService} from '../../../../service/stammtischs.service';

@Component({
  selector: 'app-drink-create-dialog',
  standalone: true,
  imports: [
    CurrencyPipe,
    FormsModule,
    MatButton,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    MatFormField,
    MatInput,
    MatLabel,
  ],
  templateUrl: './drink-create-dialog.component.html',
  styleUrl: './drink-create-dialog.component.scss',
})
export class DrinkCreateDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public drink: Drink,
    public dialogRef: MatDialogRef<DrinkCreateDialogComponent>,
    private stammtischsService: StammtischsService,
    private snackbar: MatSnackBar,
  ) {
    const id = stammtischsService.selectedStammtischID;
    if (this.drink === null) {
      this.drink = new Drink(
        0,
        id,
        '',
        '',
        0,
        0,
      );
    }
  }


  get modeChange(): boolean {
    return this.drink.id !== 0;
  }

  save(): void {
    console.log(this.drink);
    this.stammtischsService
      .createOrUpdateDrink(this.drink)
      .then(() => {
        this.snackbar.open('Getränk erfolgreich erstellt / update');
        this.dialogRef.close(true);
      })
      .catch(() => {
        this.snackbar.open('Getränk Fehler');
        this.dialogRef.close(false);
      });
  }
}
