import {Component, OnInit} from '@angular/core';
import {QuickNavComponent} from '../../../app-navigation-side/quick-nav/quick-nav.component';

@Component({
  selector: 'app-stammtisch-wrapper',
  templateUrl: './stammtisch-wrapper.component.html',
  standalone: true,
  imports: [QuickNavComponent],
})
export class StammtischWrapperComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }
}
