<h2>Finanzen</h2>
<div>
  Wie steht dein Stammtisch finanziell?
</div>
@if (0 < balance) {
  <div>
    Aktuell gut!
    <mat-icon>sentiment_very_satisfied</mat-icon>
    <br>
    Es sollten {{balance / 100 | currency}} in der Kasse oder als Getränke vorhanden sein.
  </div>
} @else {
  <div>
    Aktuell schlecht
    <mat-icon>sentiment_sad</mat-icon>
    <br>
    Es fehlt Geld in Höhe von {{balance / 100 | currency}}.
  </div>
}


<mat-accordion>
  <mat-expansion-panel [disabled]="debit_disabled">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Schulden von Mitgliedern: {{debit / 100 | currency}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-balance-chart-debt/>
  </mat-expansion-panel>
  <mat-expansion-panel [disabled]="deposit_disabled">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Guthaben von Mitgliedern: {{deposit / 100 | currency}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-balance-chart-deposit/>
  </mat-expansion-panel>
</mat-accordion>
