import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn} from '@angular/router';
import {StammtischsService} from '../../service/stammtischs.service';

export const stammtischResolver: ResolveFn<number> = (
  route: ActivatedRouteSnapshot,
  // state: RouterStateSnapshot,
) => {
  const id: number = Number(route.paramMap.get('id'));
  inject(StammtischsService).selectedStammtischID = id;
  console.warn('Switch to stammtisch id: ' + id.toString());
  return id;
};
